jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $("header").sticky({
        topSpacing: 0,
        zIndex: 50,
    });

    $(".scroll-mouse").on("click", function () {
        scrollToElement($(this).attr("href"), 1000);
    });

    $(".cart-toggle").on("click", function () {
        $(".cart-dropdown").toggleClass("show");
    });

    $(".cart-close").on("click", function () {
        $(".cart-dropdown").removeClass("show");
    });

    $(document).on("click", function (e) {
        if (
            $(".cart-dropdown").has(e.target).length === 0 &&
            $(".cart-toggle").has(e.target).length === 0 &&
            $(".cart-close").has(e.target).length === 0
        ) {
            $(".cart-dropdown").removeClass("show");
        }

        if (
            $(".productsearchbox").has(e.target).length === 0 &&
            $(".suggestsearch").has(e.target).length === 0
        ) {
            $(".suggestsearch").remove();
            $(".searchinput").val("");
        }
    });

    if ($(window).width() < 1024.98) {
        $(".search-toggle").on("click", function () {
            $(".search-wrapper").fadeToggle("");
        });

        $(".side-nav-toggle").on("click", function () {
            $(".side-nav-menu").slideToggle("");
        });

        $(document).on("click", function (e) {
            if (
                $(".search-toggle").has(e.target).length === 0 &&
                $(".search-wrapper").has(e.target).length === 0
            ) {
                $(".search-wrapper").fadeOut("");
            }
        });

        $(".has-sub").each(function () {
            let _toggle = $(this).find(".sub-toggle");
            let _sub = $(this).find(".sub-menu");
            let _siblingsSub = $(this).siblings().find(".sub-menu");
            _toggle.click(function () {
                _sub.slideToggle();
                _siblingsSub.slideUp();
            });
        });
        $(".has-child").each(function () {
            let _toggle = $(this).find(".child-toggle");
            let _child = $(this).find(".child-menu");
            let _siblingsChild = $(this).siblings().find(".child-menu");
            _toggle.click(function () {
                _child.slideToggle();
                _siblingsChild.slideUp();
            });
        });
    }

    $.fancybox.defaults.parentEl = "form";

    $(".datepicker").datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        regional: "vn",
    });

    var isLoading = false;
    if ($("a.btn-scroll-more").length) {
        var height = $(".boundary-footer").height();
        $(window).scroll(function () {
            if (
                $(window).scrollTop() + $(window).height() >=
                $(document).height() - height
            ) {
                if (isLoading == false) {
                    isLoading = true;
                    $("a.ajaxpagerlink").trigger("click");
                }
            }
        });
    }

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("span").toggleText("Xem thêm", "Rút gọn");
        $(this).find("em").toggleClass("fa-chevron-down fa-chevron-up");
    });

    $(".image-trigger-view-more").on("click", function () {
        $(".product-detail-image-trigger ul li:nth-child(n+9)").toggle();
        $(".image-trigger-view-more")
            .find("span")
            .toggleClass(" fa-chevron-down fa-chevron-up");
    });

    $(".product-detail-attr .tab-content .expand-content").each(function () {
        if ($(this).height() < 800) {
            $(this).next().hide();
        } else {
            $(this).next().show();
        }
    });

    $(".commentpanel").appendTo($(".product-detail-comment-wrap"));

    collapseComponent();
    newsSlider();
    productSlider();
    productSubnavSlider();
    productDetailSlide();
    flashSaleCountdown();
    // spinInput();
    previewImageUpload();
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed,
    );
};

function parseDate(date) {
    var dateInit = date;
    var datearray = dateInit.split(" ");
    var dateNeedFormat = datearray[0];
    var dateNeedFormatArray = dateNeedFormat.split(/\//);
    var dateAfterFormat = [
        dateNeedFormatArray[1],
        dateNeedFormatArray[0],
        dateNeedFormatArray[2],
    ].join("/");
    return [dateAfterFormat, datearray[1]].join(" ");
}

function flashSaleCountdown() {
    $(".deal-countdown").each(function () {
        var date = $(this).data("countdown");
        var formatDate = parseDate(date);
        $(this).countdown(formatDate, function (event) {
            $(this).html(event.strftime("%-D ngày %H:%M:%S"));
        });
    });
}

function collapseComponent() {
    $(document).on("click", ".collapse-trigger", function () {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
        return false;
    });
}

function spinInput() {
    $(".btn-spin").click(function () {
        var $button = $(this);
        var type = $(this).data("spin");
        var oldValue = $button.parent().find("input").val();

        if (type == "inc") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
        //   AjaxCart.updatecart();
    });
}

const menuTopMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-left",
    desktopMethod: "appendTo",
    breakpoint: 1024.98,
}).watch();

function previewImageUpload() {
    inputChange();
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.type === "attributes") {
                inputChange();
                $(".image-upload .ruRemove").each(function (index) {
                    $(this).attr("data-index", index);
                });
                $(".preview-item").each(function (index) {
                    $(this).attr("data-index", index);
                });
            }
        });
    });

    var observerRuInput = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.type === "childList") {
                $(".image-upload .ruRemove").on("click", function () {
                    var index = $(this).attr("data-index");
                    $(`.preview-item[data-index=${index}]`).remove();
                });
            }
        });
    });

    observer.observe(
        document.querySelector(".image-upload input[type='hidden']"),
        {
            attributes: true,
        }
    );

    observerRuInput.observe(document.querySelector(".ruInputs"), {
        childList: true,
    });

    let i = 0;
    function inputChange() {
        $(".ruFileInput").on("change", function (event) {
            $(".gallery-preview").append(
                `<div class="preview-item" data-index="${i}"><img src=${URL.createObjectURL(
                    event.target.files[0]
                )} /></div>`
            );
            i++;
        });
    }
}

const sharingImagesSlider = new Swiper(
    ".sharing-images-slider .swiper-container",
    {
        slidesPerView: 5,
        spaceBetween: 30,
        autoplay: {
            delay: 3500,
        },
        navigation: {
            nextEl: ".sharing-images-slider .swiper-next",
            prevEl: ".sharing-images-slider .swiper-prev",
        },
        breakpoints: {
            1280: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            576: {
                slidesPerView: 1.5,
                spaceBetween: 15,
            },
        },
    }
);

const customerSlider = new Swiper(".customer-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        nextEl: ".customer-slider .swiper-next",
        prevEl: ".customer-slider .swiper-prev",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
});

function newsSlider() {
    $(".news-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("news-slider-" + index);
        $this.find(".swiper-prev").addClass("news-slider-prev-" + index);
        $this.find(".swiper-next").addClass("news-slider-next-" + index);

        var newsSlider = new Swiper(
            ".news-slider-" + index + " .swiper-container",
            {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".news-slider-prev-" + index,
                    nextEl: ".news-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.15,
                        spaceBetween: 10,
                    },
                },
            }
        );
    });
}

function productSubnavSlider() {
    let hasActive = $(".product-subnav-item.active").length;
    let activeIndex = 0;
    let slideCount = $(".product-subnav-slider").data("slides");
    if (hasActive > 0) {
        activeIndex = $(".product-subnav-item.active").parent().index();
    }
    if (slideCount === undefined) {
        slideCount = 8;
    }
    let productSubnavSlider = new Swiper(
        ".product-subnav-slider .swiper-container",
        {
            slidesPerView: slideCount,
            spaceBetween: 40,
            initialSlide: activeIndex,
            navigation: {
                nextEl: ".product-subnav-slider .swiper-next",
                prevEl: ".product-subnav-slider .swiper-prev",
            },
            breakpoints: {
                1440: {
                    slidesPerView: 7,
                    spaceBetween: 30,
                },
                1280: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 4.5,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
            },
        }
    );
}

function productDetailSlide() {
    let productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 20,
            direction: "vertical",
            observer: true,
            observeParents: true,
            slideToClickedSlide: true,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                    direction: "horizontal",
                    freeMode: true,
                },
                768: {
                    slidesPerView: 3.5,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                576: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
            },
        }
    );

    let productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            pagination: {
                el: ".product-detail-images .swiper-slide-count",
                type: "fraction",
            },
            thumbs: {
                swiper: productDetailThumbnail,
            },
            navigation: {
                prevEl: ".product-detail-thumbnail .swiper-prev",
                nextEl: ".product-detail-thumbnail .swiper-next",
            },
        }
    );

    $(".product-detail-image-trigger .trigger-image").each(function (index) {
        let indexSlide = index;
        let _this = $(this);
        _this.on("click", function () {
            productDetailImages.slideTo(indexSlide);
        });
    });
}

function productSlider() {
    $(".product-row-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 2;
        }
        $this.addClass("product-row-slider-" + index);
        $this.find(".swiper-prev").addClass("product-row-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-row-slider-next-" + index);

        var productRowSlider = new Swiper(
            ".product-row-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-row-slider-prev-" + index,
                    nextEl: ".product-row-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2.25,
                        spaceBetween: 15,
                        freeMode: true,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 15,
                        freeMode: true,
                    },
                    576: {
                        slidesPerView: 1.25,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                },
            }
        );
    });

    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2.25,
                        spaceBetween: 15,
                        freeMode: true,
                    },
                    768: {
                        slidesPerView: 1.4,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                    576: {
                        slidesPerView: 1.25,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                },
            }
        );
    });
}

const homeBannerSlider = new Swiper(".home-banner-slider .swiper-container", {
    pagination: {
        el: ".home-banner-slider .swiper-pagination",
        clickable: true,
    },
});

const mediaSlider = new Swiper(".media-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: ".media-slider .swiper-prev",
        nextEl: ".media-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
});

const partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 4,
    navigation: {
        prevEl: ".partner-slider .swiper-prev",
        nextEl: ".partner-slider .swiper-next",
    },
    autoplay: {
        delay: 3000,
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 2.5,
        },
        576: {
            slidesPerView: 1.5,
        },
    },
});
